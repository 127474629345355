var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities,"footer-props":{ 'itemsPerPageOptions': [50, 100, 200, -1] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\payments\\list.3684')))]),_c('v-spacer')],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.trip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trip? item.trip.name : '')+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currency('RUB'))}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.PAYMENT_STATUS_TITLE[item.status]))+" "),(_vm.user.adminAccess && _vm.user.adminAccess.payments >= _vm.ACCESS.WRITE)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('v-list',_vm._l((_vm.PAYMENT_STATUS_TITLE),function(status,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setStatus(item._id, index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(status)))])],1)}),1)],1):_vm._e()]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.remove(item._id)}}},[_vm._v("mdi-close-octagon-outline")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }