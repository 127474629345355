<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\payments\\list.3684') }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.trip`]="{ item }">
            {{ item.trip? item.trip.name : '' }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
            {{ item.user.firstname }} {{ item.user.lastname }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
            {{ item.amount }} <span v-html="currency('RUB')"/>
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(PAYMENT_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.payments >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in PAYMENT_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.remove`]="{ item }">
            <v-icon color="primary" @click="remove(item._id)">mdi-close-octagon-outline</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { PAYMENT_STATUS_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'PaymentsList',
        components: {
        },
        data: () => ({
            ACCESS,
            PAYMENT_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('payments', ['entities']),
        },
        async mounted() {
            await store.dispatch('payments/fetch', {});
            return this.headers = [
                { text: this.$t('src\\views\\payments\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\payments\\list.trip'), value: 'trip' },
                { text: this.$t('src\\views\\payments\\list.user'), value: 'user' },
                { text: this.$t('src\\views\\payments\\list.amount'), value: 'amount' },
                { text: this.$t('src\\views\\payments\\list.status'), value: 'status' },
                { text: this.$t('src\\views\\payments\\list.reason'), value: 'reason' }
            ]
        },
        methods: {
            currency (value) {
                return {
                    'EUR': '&#8364;', 
                    'USD': '&#36;',
                    'RUB': '&#8381;',
                }[value]
            },
            async remove(id) {
                await store.dispatch('payments/delete', { id });
                await store.dispatch('payments/fetch', {});
            },
            async setStatus(id, status) {
                await store.dispatch('payments/setStatus', { id, status });
                await store.dispatch('payments/fetch', {});
            }
        }
    }
</script>
